import { HttpClient, HttpErrorResponse, HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { WebMessageAction } from '@utils/interfaces/web-messages/web-message-action.enum';
import { IVehicleDataGroup } from "@utils/interfaces/key-readout/vehicle-data-group.interface";
import { IKeyDataResponse } from "@utils/interfaces/key-readout/key-readout-response.interface";
import { Buffer } from 'buffer';
import * as pako from 'pako';
import { IServiceKeyData } from '@utils/interfaces/key-readout/service-key-data/service-key-data.interface';
import { IFieldIdValuePair } from '@utils/interfaces/key-readout/id-value-pair.interface';
import { IServiceIntervalDisplay } from '@utils/interfaces/key-readout/service-key-data/service-interval-display.interface';

/**
 * This service handles the key data information
 */
@Injectable({
  providedIn: 'root',
})
export class VehicledataService {
  // contains the last edited key data element
  public replaySubject = new ReplaySubject<IVehicleDataGroup>(1);
  private readonly KM_TO_MI_CONVERSION = 0.6;


  /**
   * Creates an instance of VehicledataService.
   *
   * @param http methods to perform HTTP requests
   * @param router provide navigation among the views
   * @param sessionStorageService handles the session storage communication
   * @param loadingIndicatorService contains the handling of the loading indicator
   */
  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    // register web message handler for keydata requests
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) => {
      this.receiveKeyReadoutStatusFromTrayApp(event);
    });
  }

  /**
   * Listen for web messages coming from the trayapp to send the language to webapp
   *
   * @param event message event with the web-message action and data
   */
  public receiveKeyReadoutStatusFromTrayApp(event: MessageEvent): Promise<void> {
    const { action, data } = JSON.parse(event.data);

    if (action == WebMessageAction.SendKeyDataStart) {

      // If the key data view is not yet open, navigate to that view
      if (this.router.url !== '/data') {
        this.router.navigate(['/data']);
      }
      this.loadingIndicatorService.show();

    } else if (action == WebMessageAction.SendKeyDataEnd) {

      // evaluate the value of the supplied data
      if (!data || this.isSendKeyDataFailure(data)) {

        // resetting key data tab..
        this.processData(null);

      } else if (this.isSendKeyDataSuccess(data)) {

        // retrieving vehicle data from remote..
        return this.getVehicleData();

      } else if (data.length > 0) { // directly passed via web-message

        try {

          const buffer = Buffer.from(data, 'base64');
          const inflated = pako.inflate(buffer);
          const decoded = new TextDecoder().decode(inflated)
          this.processData(JSON.parse(decoded));

        } catch (err) {

          return this.getVehicleData(); // fallback
        }

      }
    }
    return Promise.resolve();
  }

  /**
   * Get the key data from the '/da/keydata/details/latest' endpoint and pass it on to the vehicledata service subject
   */
  getVehicleData(): Promise<void> {
    // request header
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = {
      headers: headers,
    };
    return this.http
      .post<IKeyDataResponse>('/da/keydata/details/latest', null, options)
      .toPromise()
      .then(
        // Should the request be successful
        (res) => {
          this.processData(res.data);
        },
        // In case of error
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.processData(null)
          }
        }
      )
  }

  public processData(data): void {
    try {
      if (data && Object.keys(data).length > 0) {

        // Set vin for the key data page to know data is available
        const incomingVin = data.vehicleData.data.vin.value;
        this.sessionStorageService.setItem('vin', incomingVin.toString());

        // If the selected unit is miles, convert fields in serviceKeyData
        if (localStorage.getItem('selectedUnit') === 'Miles') {
          this.convertUnitsToMiles(data.serviceKeyData);
        }

        // Add the info that key data is available to the session storage
        this.replaySubject.next({
          version: data.version,
          vehicleData: data.vehicleData,
          serviceKeyData: data.serviceKeyData,
          emissionData: data.emissionData
        });
        this.sessionStorageService.setItem('keyDataAvailable', 'true');

      } else { // Invalid data

        this.replaySubject.next({
          version: null,
          vehicleData: null,
          serviceKeyData: null,
          emissionData: null
        });
        this.sessionStorageService.removeAllItems();
      }
    } catch (err) {
    } finally {
      this.loadingIndicatorService.hide();
    }
  }

  private convertUnitsToMiles(serviceKeyData: IServiceKeyData): void {
    // Fields inside serviceIntervalDisplay that are implicitly in kilometers
    const implicitKilometerFields = ['inspectionDistance', 'oilDistance', '']; // Add more field names as needed

    // Convert fields with an explicit "km" unit within serviceKeyData
    Object.keys(serviceKeyData).forEach((key) => {
      const field = serviceKeyData[key as keyof IServiceKeyData] as IFieldIdValuePair | undefined;

      if (field && field.unit === 'km') {
        const value = Number(field.value);
        if (!isNaN(value)) {
          field.value = this.convertKilometersToMiles(value);
          field.unit = 'mi';
        }
      }
    });

    Object.keys(serviceKeyData.serviceIntervalDisplay).forEach((key) => {
      const field = serviceKeyData.serviceIntervalDisplay[key as keyof IServiceIntervalDisplay] as IFieldIdValuePair | undefined;

      if (field && field.unit === 'km') {
        const value = Number(field.value);
        if (!isNaN(value)) {
          field.value = this.convertKilometersToMiles(value);
          field.unit = 'mi';
        }
      }
    });

    // Access serviceIntervalDisplay and convert implicit kilometer fields
    const serviceInterval = serviceKeyData.serviceIntervalDisplay;
    if (serviceInterval) {
      implicitKilometerFields.forEach((fieldKey) => {
        const field = serviceInterval[fieldKey as keyof IServiceIntervalDisplay] as IFieldIdValuePair | undefined;

        // If the field value is a string with distance in km, convert it
        if (field && typeof field.value === 'string') {
          field.value = this.convertTextDistanceToMiles(field.value);
        }
      });
    }
  }

  private convertTextDistanceToMiles(text: string): string {
    const kmToMilesConversion = this.KM_TO_MI_CONVERSION;

    return text.replace(/(\d+)\s*km/, (match, p1) => {
      const kmValue = parseInt(p1, 10);
      const milesValue = Math.round(kmValue * kmToMilesConversion);
      return `${milesValue} mi`;
    });
  }

  private convertKilometersToMiles(value: number): number {
    return Math.round(value * this.KM_TO_MI_CONVERSION);
  }




  isSendKeyDataSuccess(value: string): boolean {
    return value && ['success', 'SendKeyDataSuccessful'].includes(value);
  }

  isSendKeyDataFailure(value: string): boolean {
    return value && ['failure', 'SendKeyDataFailed'].includes(value);
  }

  /**
   * complete the subject on destroy
   */
  ngOnDestroy() {
    this.replaySubject.complete();
  }
}
