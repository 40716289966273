<groupui-header>
  <groupui-grid-row flexible>
    <!-- left side of the header -->
    <groupui-grid-col>
      <a href="#">
        <img class="gsk-logo-icon" src="assets/logos/landscape_white.svg" alt="gsk logo" />
      </a>
    </groupui-grid-col>

    <!-- right side of the header -->
    <groupui-grid-col class="internal-banner">
      <!-- internal banner -->
      <img type="image/svg+xml" src="assets/internal-banner/Internal-Tag-Light.svg" alt="internal banner" />

      <div class="settings">
        <groupui-button size="s" inverted="true" variant="tertiary" (click)="toggleSettingsMenu($event)">
          <div class="settings-button">
            <img src="assets/icons/shell/settings-16.svg" alt="settings icon" />
          </div>
        </groupui-button>

        <!-- Settings menu popup -->
        <div class="settings-menu" *ngIf="showSettingsMenu">
          <div class="setting-item">
            <label>Language:</label>
            <app-language-dropdown></app-language-dropdown>
          </div>
          <div class="setting-item">
            <label>Unit:</label>
            <app-unit-dropdown></app-unit-dropdown>
          </div>
        </div>
      </div>


      <!-- logout functionality -->
      <app-logout-button></app-logout-button>
    </groupui-grid-col>
  </groupui-grid-row>
</groupui-header>