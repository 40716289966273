import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage } from '@utils/interfaces/language.interface';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import languages from '@assets/data/languages.json';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { WebMessageFormat } from '@utils/interfaces/web-messages/web-message-format.interface';
import { WebMessageAction } from '@utils/interfaces/web-messages/web-message-action.enum';
import { DefaultSettingsConstants } from '@utils/constants/default-settings.constants';

/**
 * This dropdown component displays the currently selected language and is used to change the language.
 */
@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit, AfterViewInit {
  // default language
  @Input() currentLanguage: ILanguage = {
    country: 'Großbritannien',
    displayCountry: 'English',
    countryCode: 'GB',
  };

  // mouse event status, if the dropdown content was clicked
  private contentClicked = false;
  // all available languages
  public languages: ILanguage[] = languages.languages;

  // number of suported languages and items in the dropdown
  private numberOfLanguages = this.languages.length;
  // Element which is hovered, by the selection with keyboard
  public hoverElement: number | undefined = undefined;
  /**
   * Creates an instance of LanguageDropdownComponent.
   *
   * @param translateService communication with the internationalization (i18n) library
   * @param localStorageService asses to local storage information
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param defaultSettings Recurring default values
   */
  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private defaultSettings: DefaultSettingsConstants
  ) {
    // hide the loadingindicator at beginning of the language change
    this.translateService.onLangChange
      .pipe()
      .subscribe((_) => this.loadingIndicatorService.hide());
  }

  // listens to a click outside the component and closes the dropdown if it occurs
  @HostListener('document:click') clickOutside(): void {
    if (!this.contentClicked) {
      this.closeDropdown();
    }
    this.contentClicked = false;
  }

  // listens to a click inside the component
  @HostListener('click') clickInside(): void {
    this.contentClicked = true;
  }

  /**
   * Check if a preselected lanuage is available
   */
  ngOnInit(): void {
    const preselectedLanguage: string = this.localStorageService.getItem(
      this.defaultSettings.localStorageLanguageVariable
    );
    const language = this.languages.find(
      (lang) => lang.countryCode === preselectedLanguage
    );
    if (language !== undefined) {
      this.currentLanguage = language;
    }
  }

  ngAfterViewInit(): void {
    // get the current language from trayapp
    this.requestToGetLanguageFromTrayApp();
  }

  /**
   * Listen for web messages coming from the trayapp to send the language to webapp
   *
   * @param event message event with the Webmessage action and data
   */
  public receiveMessageFromTrayApp(event: MessageEvent): void {
    const { action, data } = JSON.parse(event.data);
    // Handle only messages with 'set_language' action, ignore others
    if (action != WebMessageAction.SetLanguage) {
      return;
    }
    // Extract language code and find out the corresponding language entry
    const languageShort = data.split('-')[1];
    const language = this.languages.find(
      (lang) => lang.countryCode === languageShort
    );
    if (language !== undefined) {
      this.changeLanguage(language.countryCode, true);
    }
  }

  /**
   * Send a web message to get the current language from trayapp
   */
  public requestToGetLanguageFromTrayApp(): void {
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) =>
      this.receiveMessageFromTrayApp(event)
    );
    const message: WebMessageFormat = {
      action: WebMessageAction.GetLanguage,
      data: '',
    };
    window.chrome?.webview.postMessage(JSON.stringify(message));
  }

  /**
   * Change the language by clicking on a dropdown item
   *
   * @param language Language to be changed to (displayed on selected dropdown item)
   * @param trayAppInducedChange is used as a flag to know if the value change was induced by the trayapp
   */
  public changeLanguage(
    countryCode: string,
    trayAppInducedChange: boolean
  ): void {
    // set the current/storage/displayed language to the selected

    const language = this.languages.find(
      (lang) => lang.countryCode === countryCode
    );
    if (language !== undefined) {
      this.currentLanguage = language;
    }

    this.localStorageService.setItem(this.defaultSettings.localStorageLanguageVariable, countryCode);
    // Change the language by using the translate service
    this.translateService.use(countryCode);
    this.translateService.setDefaultLang(countryCode);

    // close the dropdown
    this.closeDropdown();
    // pass the language to the trayapp if the event origin was FE code
    if (!trayAppInducedChange) {
      const message: WebMessageFormat = {
        action: WebMessageAction.SetLanguage,
        data: countryCode,
      };
      const data = JSON.stringify(message);
      window.chrome?.webview.postMessage(data);
    }
  }

  /**
   * Close the dropdown, by removing the open attribute from the dropdown content.
   */
  private closeDropdown(): void {
    this.hoverElement = undefined;
    document.getElementById('language-dropdown')?.removeAttribute('open');
  }

  /**
   * It listens for incoming keyboard events to navigate in the language dropdown (ArrowUp and ArrowDown),
   * to select an element (Space or Enter) or to leave the dropdown again (Tab or Escape).
   * This method only exists for navigation with the keyboard.
   *
   * @param event When a key is pressed on the keyboard
   */
  public onKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      // Selecting a language
      case 'Space':
      case 'Enter':
        const selectedLanguage = document.getElementById(
          'language-dropdown-' + this.hoverElement
        )?.innerText;

        // only if the dropdown is already open a dropdown item with an id will be found
        if (selectedLanguage) {
          const languageObject = this.languages.find(
            (lang: ILanguage) => lang.displayCountry === selectedLanguage
          );
          this.changeLanguage(languageObject.countryCode, false);
        }
        break;
      // close the dropdown
      case 'Escape':
      case 'Tab':
        this.closeDropdown();
        break;
      case 'ArrowDown':
        // Selecting the first dropdown element
        if (this.hoverElement === undefined) {
          this.hoverElement = 0;
          // navigate downwards through the dropdown
        } else if (this.hoverElement < this.numberOfLanguages - 1) {
          ++this.hoverElement;
        }
        this.hoverAndFocusElement();
        break;
      // navigate upwards through the dropdown
      case 'ArrowUp':
        if (this.hoverElement !== undefined && this.hoverElement > 0) {
          --this.hoverElement;
          this.hoverAndFocusElement();
        }
        break;
    }
  }

  /**
   * A selected dropdown item is hovered and moved into the visible area.
   */
  private hoverAndFocusElement(): void {
    const id = 'language-dropdown-' + this.hoverElement;
    document.getElementById(id)?.scrollIntoView();
  }
}
