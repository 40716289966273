import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-unit-dropdown',
  templateUrl: './unit-dropdown.component.html',
  styleUrls: ['./unit-dropdown.component.scss']
})

export class UnitDropdownComponent {

  units: string[] = ['Miles', 'Kilometers'];
  currentUnit: string = 'Kilometers'; // Default unit
  hoverElement: number | null = null;

  
  // mouse event status, if the dropdown content was clicked
  private contentClicked = false;

  // listens to a click outside the component and closes the dropdown if it occurs
  @HostListener('document:click') clickOutside(): void {
    if (!this.contentClicked) {
      this.closeDropdown();
    }
    this.contentClicked = false;
  }


  constructor() {
    // Load the saved unit from localStorage, if available
    const savedUnit = localStorage.getItem('selectedUnit');
    if (savedUnit) {
      this.currentUnit = savedUnit;
    }
  }

  changeUnit(unit: string) {
    this.currentUnit = unit;
    // Save the selected unit to localStorage
    localStorage.setItem('selectedUnit', unit);

    // Close the dropdown
    const dropdown = document.getElementById('unit-dropdown') as HTMLDetailsElement;
    dropdown.open = false;
  }

  onKeyDown(event: KeyboardEvent) {
    // Logic for keyboard accessibility, if needed
  }

  closeDropdown() {
    const dropdown = document.getElementById('unit-dropdown') as HTMLDetailsElement;
    dropdown.open = false;
    this.contentClicked = false;
  }
}

