import { Component, ElementRef, OnInit } from '@angular/core';
import { IHistoricalReadoutRecord } from '@utils/interfaces/historical-readout/historical-readout-record.interface';
import { IPagination } from '@utils/interfaces/pagination/pagination.interface';

import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import { HistoricalReadoutFilterResponse } from '@utils/interfaces/historical-readout/historical-readout-filter.interface';
import { IVehicleDataGroup } from '@utils/interfaces/key-readout/vehicle-data-group.interface';
import { HistoricalReadoutService } from '@services/historical-readout/historical-readout.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { DefaultSettingsConstants } from '@utils/constants/default-settings.constants';
import moment from 'moment';

@Component({
  selector: 'app-historical-readout',
  templateUrl: './historical-readout.component.html',
  styleUrls: ['./historical-readout.component.scss'],
})
export class HistoricalReadoutComponent implements OnInit {
  // Default values for te date-time-picker
  public minDateFrom = new Date();
  public maxDateFrom = new Date();
  public minDateTo = new Date();
  public maxDateTo = new Date();

  // the maximum time span that can be mapped in the date-time-picker in days
  readonly maxDateRangeInDays: number = 5;

  // page size options of the pagination component
  public paginationPageSizeOptions = "[10, 25, 50]";

  // pagination value
  public pagination: IPagination<IHistoricalReadoutRecord> = {
    page_number: 1,
    page_size: 50,
    total_count: 0,
    total_pages: 1,
    page_contents: [],
  };
  // all available brand codes for the filter component
  public availableBrandCodes: string[] = [];
  // all available vins codes for the filter component
  public availableVin: string[] = [];

  // key data to be display on the key data page
  public groupServiceKey?: IVehicleDataGroup;

  // Filters object for historical readout
  public filterFormGroup = this.initializeFilterForm();

  // Currently selected historical readout table row
  public highlightedRowId: number | undefined = undefined;
  // with the keyboard selected row id
  public selectedRowId: number | undefined = undefined;
  // amount of rows in the table
  public availableRows = 0;
  // flag to set the event emitter on the tabl  e only one time
  private fistTimeTableAccess = true;
  // modal window close button only reachable if the modal window is open
  private modalWindowCloseButton: HTMLElement;

  // flag whether the modal window has just been opened.
  // is used for the initial focus of the modal window with the tab key of the keyboard navigation.
  // is set to false after the first tab event
  private firstTabOnModalWindow = false;

  private table: HTMLElement;

  /**
   * Creates an instance of the EditGdprComponent
   *
   * @param historicalReadoutService handles the historical readout information
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param ngxMatDateAdapter date adapter module
   * @param translateService communication with the internationalization (i18n) library
   * @param localStorageService handles the local storage communication
   * @param defaultSettings Recurring default values
   */
  constructor(
    private historicalReadoutService: HistoricalReadoutService,
    public loadingIndicatorService: LoadingIndicatorService,
    private ngxMatDateAdapter: NgxMatDateAdapter<any>,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private defaultSettings: DefaultSettingsConstants
  ) {
    // initial language status for the date-time-picker
    this.ngxMatDateAdapter.setLocale(
      this.localStorageService.getItem(
        this.defaultSettings.localStorageLanguageVariable
      )
    );
  }

  ngOnInit(): void {
    // define the initial timerange (last 5 days) for the date-time-range-picker
    const now = new Date();
    this.minDateFrom = moment().subtract(this.maxDateRangeInDays, 'days').startOf('day').toDate();
    this.minDateTo.setDate(now.getDate() - this.maxDateRangeInDays);

    const filterDataJson = this.getFormDataValue();

    this.validateTimerange();

    forkJoin({
      // Make requests to get filters and paginated data asynchronously
      historicalDataFiltersResponse: this.getHistoricalDataFilters(),
      historicalReadoutDataResponse: this.getHistoricalData(
        this.pagination.page_size,
        this.pagination.page_number,
        filterDataJson
      ),
    })
      .pipe(
        map((response) => {
          const filtersResponse = response.historicalDataFiltersResponse;
          const historicalReadoutDataResponse =
            response.historicalReadoutDataResponse;
          return { filtersResponse, historicalReadoutDataResponse };
        })
      )
      .subscribe(
        (data) => {
          // get all available brand codes for the filter component
          this.availableBrandCodes = data.filtersResponse.brandCodes;
          // get all available VINs for the filter component
          this.availableVin = data.filtersResponse.vins;
          this.pagination = data.historicalReadoutDataResponse;
          this.availableRows = this.pagination.page_contents.length;
        },
        (error) => {
          console.error(error);
        }
      );

    // update language status for the date-time-picker if language is changing
    this.translateService.onLangChange.pipe().subscribe((_) => {
      this.ngxMatDateAdapter.setLocale(
        this.localStorageService.getItem(
          this.defaultSettings.localStorageLanguageVariable
        )
      );
    });
  }

  /**
   * returns the brand name of the car
   *
   * @param brandCode brand code
   */
  getBrandNameByCode(brandCode: string): string {
    switch (brandCode) {
      case 'A': {
        return 'Audi';
      }
      case 'S': {
        return 'Seat';
      }
      case 'C': {
        return 'Škoda';
      }
      case 'V': {
        return 'Volkswagen';
      }
      case 'N': {
        return 'VW Commercial';
      }
      default: {
        return 'Unknown';
      }
    }
  }

  /**
   * Gets the paginated historical readout table data
   *
   * @param page_size page size of the pagination response
   * @param page_number page number of the pagination response
   * @param body contains the filters that are used to filter pagination data
   */
  getHistoricalData(
    page_size: number,
    page_number: number,
    body = {}
  ): Promise<IPagination<IHistoricalReadoutRecord>> {
    return this.historicalReadoutService.getHistoricalData(
      page_size,
      page_number,
      body
    );
  }

  /**
   * Gets the brand codes used for filtering
   */
  getHistoricalDataFilters(): Promise<HistoricalReadoutFilterResponse> {
    return this.historicalReadoutService.getHistoricalDataFilters();
  }


  /**
   * Gets the displayed elements count
   */
  getDisplayedElements() {
    let startIndex = (this.pagination.page_number - 1) * this.pagination.page_size + 1;
    let endIndex = Math.min(startIndex + this.pagination.page_size - 1, this.pagination.total_count);
    return `${startIndex}-${endIndex}`;
  }


  /**
   * Filters data and returns the paginated result
   */
  onFilterData(): void {
    if (this.filterFormGroup.valid) {
      const filterDataJson = this.getFormDataValue();
      this.getHistoricalData(
        this.pagination.page_size,
        this.pagination.page_number,
        filterDataJson
      ).then(
        (data) => {
          this.pagination = data;
        },
        (error) => {
        }
      );
    }
  }

  /**
   * Highlights or removes highlight the clicked historical readout table record when we click it
   *
   * @param open the modal window should be opened (true) or closed (false)
   * @param requestId unique number used to identify historical readout table records.
   */
  toggleModalWindow(open: boolean, request_id?: number) {
    const modalWindow: HTMLElement = document.getElementById('keyDataModal')!;

    if (open) {
      this.historicalReadoutService.getHistoricalRecord(request_id).subscribe({
        next: (res) => {
          this.groupServiceKey = res.data;
        },
        complete: () => {
          modalWindow['displayed'] = open;
          this.firstTabOnModalWindow = open;
        }, error: () => {
          this.groupServiceKey = undefined;
        }
      })

      // after the window has been opened, an autofocus and a tab index are added to the close button.
      // This makes it controllable with the keyboard.
      setTimeout(() => {
        this.modalWindowCloseButton = document
          .querySelector('groupui-modal')
          ?.shadowRoot?.getElementById('close-button')!;
        if (this.modalWindowCloseButton) {
          this.modalWindowCloseButton.setAttribute('autofocus', '');
          this.modalWindowCloseButton.setAttribute('tabindex', '0');

          // add a keydown event to the close button to be able to close it with the Enter or Space key.
          this.modalWindowCloseButton.addEventListener(
            'keydown',
            (event: KeyboardEvent) => {
              switch (event.code) {
                case 'Space':
                case 'Enter':
                  this.toggleModalWindow(false);
                  break;
              }
            }
          );
        }
        // when opening a modal window element is focused, to avoid the need to click into the window for keyboard navigation.
        document.querySelector('#focusguard')?.addEventListener('focus', () => {
          this.modalWindowCloseButton.focus();
        });
      });
    }
  }

  /**
   * After a row has been selected via the keyboard and the modal window has been closed,
   * the selected row should be focussed again.
   */
  focusRowAfterModalClose() {
    // The id of the selected row is only set if the line was selected via the keyboard.
    if (this.selectedRowId !== undefined) {
      this.focusTable();
      this.highlightedRowId = this.selectedRowId;
      this.selectedRowId = undefined;
    }
  }

  /**
   * Focus the close button of the modal window on the first tab keydown
   *
   * @param event keydown keyboard event of the modal window
   */
  focusCloseButtonOnFirstTab(event: KeyboardEvent) {
    if (event.code === 'Tab' && this.firstTabOnModalWindow) {
      this.modalWindowCloseButton.focus();
      this.firstTabOnModalWindow = false;
    }
  }

  /**
   * focus groupUI table
   */
  focusTable() {
    this.table.setAttribute('autofocus', '');
    this.table.setAttribute('tabindex', '0');
    this.table.focus();
  }

  /**
   * Event that is triggered change form group value for own readouts checkbox
   */
  onToggleShowOwnReadoutsCheckbox(): void {
    this.filterFormGroup.controls.is_own.setValue(
      !this.filterFormGroup.controls.is_own.value
    );
    document.getElementById('is_own')?.focus();

    this.onFilterData();
  }

  /**
   * Event that is triggered to retrieve a new pagination response when page number changes inside GroupUIPagination component
   *
   * @param event unique number used to identify event
   */
  onGroupUiPageChange(event: any): void {
    this.pagination.page_number = event.detail.page;
    this.pagination.page_size = event.detail.size;
    this.onFilterData();
  }

  /**
   * Event that is triggered to retrieve a new pagination response page size changes inside GroupUIPagination component
   *
   * @param event unique number used to identify event
   */
  onGroupUiPageSizeChange(event: any): void {
    this.pagination.page_size = event.detail.size;
    this.onFilterData();
  }

  /**
   * Function that initializes the filter form
   */
  initializeFilterForm(): FormGroup {
    const max = moment(new Date());
    const min = moment(new Date()).subtract(this.maxDateRangeInDays, 'days');

    return new FormGroup({
      from: new FormControl(min, [
        Validators.required,
        this.validateDateFormat.bind(this),
        this.validateDateNotInFuture.bind(this),
        this.validateDateNotOlderThanMaxRange.bind(this)
      ]),
      to: new FormControl(max, [
        Validators.required,
        this.validateDateFormat.bind(this),
        this.validateDateNotInFuture.bind(this),
        this.validateDateNotOlderThanMaxRange.bind(this),
        this.validateToFieldDateRange.bind(this)
      ]),
      vin: new FormControl(''),
      brand_code: new FormControl(null),
      is_own: new FormControl(false),
    });
  }

  validateDateFormat(control: FormControl): ValidationErrors | null {
    if (!control.value || !(moment.isMoment(control.value) && control.value.isValid())) {
      return { invalidDateFormat: true };
    }
    return null;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
    }
  }

  validateDateNotOlderThanMaxRange(control: FormControl): ValidationErrors | null {
    if (control.errors && control.errors.invalidDateFormat) {
      // Skip this validation if the date format is incorrect
      return null;
    }

    if (moment.isMoment(control.value) && control.value.isValid()) {
      // Set the limit to the end of the 5th day ago
      const dateLimit = moment().subtract(this.maxDateRangeInDays, 'days').startOf('day');

      const selectedDate = control.value;
      // Check if the selected date is before the end of the 5th day ago
      return selectedDate.isBefore(dateLimit) ? { notOlderThanMaxRange: true } : null;
    }
    return null;
  }



  validateToFieldDateRange(control: FormControl): ValidationErrors | null {
    const fromControl = this.filterFormGroup?.get('from');
    if (!fromControl) {
      return null; // No "from" control available, skip validation
    }

    const from = fromControl.value;
    const to = control.value;

    if (moment.isMoment(from) && from.isValid() && moment.isMoment(to) && to.isValid()) {
      return to.isSameOrAfter(from) ? null : { invalidRange: true };
    }

    // If from or to are not valid Moment objects, do not set the invalidRange error
    return null;
  }



  validateDateNotInFuture(control: FormControl): ValidationErrors | null {
    const currentDate = new Date();
    const selectedDate = new Date(control.value);
    return selectedDate <= currentDate ? null : { futureDate: true };
  }


  getFromFieldHint(): string {
    const fromControl = this.filterFormGroup?.get('from');
    if (!fromControl) {
      return 'historical-readouts.filters.from-label';
    }

    if (fromControl.hasError('invalidDateFormat')) {
      return 'historical-readouts.errors.invalid-date-format';
    } else if (fromControl.hasError('futureDate')) {
      return 'historical-readouts.errors.future-date';
    } else if (fromControl.hasError('notOlderThanMaxRange')) {
      return 'historical-readouts.errors.not-older-than-max-range';
    }
    return 'historical-readouts.filters.from-label';
  }

  getToFieldHint(): string {
    const toControl = this.filterFormGroup?.get('to');
    if (!toControl) {
      return 'historical-readouts.filters.to-label'; // No "from" control available, skip validation
    }

    if (toControl.hasError('invalidDateFormat')) {
      return 'historical-readouts.errors.invalid-date-format';
    } else if (toControl.hasError('futureDate')) {
      return 'historical-readouts.errors.future-date';
    } else if (toControl.hasError('invalidRange')) {
      return 'historical-readouts.errors.invalid-range';
    }
    return 'historical-readouts.filters.to-label'; // Default hint
  }
  /**
   * Returns the class name that makes text red for the error message from
  */
  getHintClassForFrom(){
    const errorList = ['invalidDateFormat', 'futureDate', 'notOlderThanMaxRange']
    const fromControl = this.filterFormGroup?.get('from');
    const hasError = this.checkSpecificErrors(fromControl, errorList)
    return {'red-text': hasError}
  }

  /**
   * Returns the class name that makes text red for the error message from
   */
  getHintClassForTo(){
    const errorList = ['invalidDateFormat', 'futureDate', 'invalidRange']
    const fromControl = this.filterFormGroup?.get('to');
    const hasError = this.checkSpecificErrors(fromControl, errorList)
    return {'red-text': hasError}
  }

  /**
   *
   * @param control a form control
   * @param errorKeys list of errors to check against
   * Check if control contains any of the errors in the errorKeys array
   */
  checkSpecificErrors(control: AbstractControl, errorKeys: string[]): boolean {
    const errors = control.errors;
    return errorKeys.some(key => errors && errors[key]);
  }

  /**
   * Function that transforms the value of the filter form before submitting
   */
  getFormDataValue(): any {
    const filterDataJson = this.filterFormGroup.getRawValue();
    const payload = { ...filterDataJson };

    if (this.filterFormGroup.controls.from.value !== '') {
      payload.from = this.parseDatetime(
        this.filterFormGroup.controls.from.value?.toString()
      );
    }
    if (this.filterFormGroup.controls.to.value !== '') {
      payload.to = this.parseDatetime(
        this.filterFormGroup.controls.to.value?.toString()
      );
    }
    return payload;
  }

  /**
   * Returns a new date in ISO format as string in the format 'dd-MM-YYYY HH:mm'
   */
  parseDatetime(datetime: any): string {
    return new Date(datetime).toISOString().slice(0, 16);
  }

  /**
   * After a row has been selected with the keyboard, it should also be placed in the view.
   */
  scrollRowIntoView() {
    const hoveredRow = document.querySelectorAll(
      'groupui-table-cell.hover'
    )[0] as HTMLElement;
    hoveredRow?.scrollIntoView();
  }

  /**
   * Validate the Timerange on change
   */
  validateTimerange(): void {
    this.filterFormGroup.get('from')?.valueChanges.subscribe((fromValue) => {
      this.minDateTo = fromValue;
    });
    this.filterFormGroup.get('to')?.valueChanges.subscribe((toValue) => {
      this.maxDateFrom = toValue;
    });
  }

  /**
   * Focus table the table and handle table control with the keyboard
   */
  focusTableWithTabKey() {
    setTimeout(() => {
      this.table = document.querySelector('groupui-table')?.shadowRoot
        ?.children[0] as HTMLElement;
      this.focusTable();

      // the event emitters should only be set the first time the table is accessed.
      if (this.fistTimeTableAccess) {
        // If the table is no longer to be focused, the id of the highlighted table is reset again.
        this.table.addEventListener('focusout', (_) => {
          this.highlightedRowId = undefined;
        });

        // listen to the key events
        this.table.addEventListener('keydown', (event: KeyboardEvent) => {
          this.fistTimeTableAccess = false;
          switch (event.code) {
            // navigate down the table when the arrow down button is pressed
            case 'ArrowDown':
              if (this.highlightedRowId === undefined) {
                this.highlightedRowId = 0;
              } else if (this.highlightedRowId < this.availableRows - 1) {
                this.highlightedRowId++;
              }
              this.scrollRowIntoView();
              break;
            // navigate up the table when the arrow up button is pressed
            case 'ArrowUp':
              if (
                this.highlightedRowId !== undefined &&
                this.highlightedRowId > 0
              ) {
                this.highlightedRowId--;
                this.scrollRowIntoView();
              }
              break;
            // opening the detailed view of a table entry when the enter key or spacebar is pressed
            case 'Enter':
            case 'Space':
              this.selectedRowId = this.highlightedRowId;
              const hoveredRow = document.querySelectorAll(
                'groupui-table-cell.hover'
              )[0] as HTMLElement;

              hoveredRow.click();
              break;
          }
        });
      }
    });
  }
}
