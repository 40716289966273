import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { UtilsModule } from '@utils/utils.module';
import { DescriptionValueCellComponent } from '@ui/description-value-cell/description-value-cell.component';
import { IconCardComponent } from '@ui/icon-card/icon-card.component';
import { LanguageDropdownComponent } from '@ui/language-dropdown/language-dropdown.component';
import { UnitDropdownComponent } from '@ui/unit-dropdown/unit-dropdown.component';
import { LogoutButtonComponent } from '@ui/logout-button/logout-button.component';
import { VersionCardComponent } from './version-card/version-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { PlaceholderPipe } from '@utils/pipes/placeholder/placeholder.pipe';
import { HistoricalReadoutsCardComponent } from './historical-readouts-card/historical-readouts-card.component';


// components contained in the ui folder
const COMPONENTS = [
  DescriptionValueCellComponent,
  IconCardComponent,
  VersionCardComponent,
  HistoricalReadoutsCardComponent,
  LanguageDropdownComponent,
  UnitDropdownComponent,
  LogoutButtonComponent
];

/**
 * This module contains all necessary ui components
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GroupUiModule,
    UtilsModule,
    TranslateModule
  ],
  declarations: COMPONENTS,
  providers: [PlaceholderPipe],
  exports: [
    COMPONENTS
  ]
})
export class UiModule {
}
