<!-- Unit Dropdown -->
<details id="unit-dropdown">
  <summary>
    <groupui-tooltip position="left">
      <groupui-button size="s" inverted="false" variant="tertiary">
        <div class="unit-button">
          {{currentUnit}}
        </div>
      </groupui-button>

      <div slot="tooltip" variant="primary">{{currentUnit}}</div>
    </groupui-tooltip>
  </summary>

  <div class="dropdown-content">
    <ul id="unit-dropdown-list">
      <li *ngFor="let unit of units; index as i"
          [ngClass]="{'selected' : currentUnit == unit, 'hover-dropdown-item' : i == hoverElement}"
          (click)="changeUnit(unit)" 
          id="{{'unit-dropdown-' + i}}">
        {{unit}}
      </li>
    </ul>
  </div>
</details>
