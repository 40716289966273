import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { IFieldIdValuePair } from '@utils/interfaces/key-readout/id-value-pair.interface';
import { IServiceIntervalDisplay } from '@utils/interfaces/key-readout/service-key-data/service-interval-display.interface';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';
import { IFieldIdValueNote } from '@utils/interfaces/key-readout/id-value-note.interface';

/**
 * This component cointains distance information (mileage and service intervals)
 */
@Component({
  selector: 'app-distances',
  templateUrl: './distances.component.html',
  styleUrls: ['../../key-data.component.scss']
})
export class DistancesComponent implements OnChanges {
  // mileage information
  @Input() mileage?: IFieldIdValuePair;
  // mileage note information
  @Input() mileageNote?: IFieldIdValueNote;
  // mileage timestamp information
  @Input() mileageTimestamp?: IFieldIdValuePair;
  // mileage info
  @Input() mileageInfo?: IFieldIdValueNote;
  // service interval to display
  @Input() serviceInterval?: IServiceIntervalDisplay;
  // highlight mileage related text
  @Input() highlightText?: boolean;
  // additional information which should displayed on the icon card
  addInfoText: string[] = [];
  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/distance-24.svg';
  readonly translationPath = TranslationPathConstants.distances;

  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();

  // flag if show empty fields on the key data is selected
  showEmpty = true;
  // flag if the incomming data is empty
  isEmpty = true;

  /**
   * Creates an instance of DistancesComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService bringt die fieldIds in ein vom TranslationModule lesbare Form
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public mapService: MapService,
    private missingDataService: MissingDataService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const mileage = changes.mileage.currentValue;
    const serviceInterval = changes.serviceInterval.currentValue;

    // check if the vehicle condition data are available
    this.isEmpty = this.missingDataService.isDataEmpty(
      { mileage, serviceIntervalDisplay: serviceInterval },
      DataGroupsConstants.distancesData
    );

    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);

    if (this.isEmpty) {
      if (!this.addInfoText.includes(DataStatusConstants.noDistancesData)) {
        // add the information of missing data to the icon card
        this.addInfoText.push(DataStatusConstants.noDistancesData);
      }
    } else {
      this.addInfoText = [];
    }
  }
  
}
