import { Component, ElementRef, HostListener } from '@angular/core';

/**
 * This component displays the header
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  showSettingsMenu: boolean = false;


  constructor(private eRef: ElementRef) { }


  // listens to a click outside the component and closes the dropdown if it occurs
  toggleSettingsMenu(event: Event): void {
    this.showSettingsMenu = !this.showSettingsMenu;
    event.stopPropagation(); // Prevents this click from closing the menu immediately
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showSettingsMenu = false; // Close the menu if clicked outside
    }
  }


}
