<table data-pdfmake="{'widths':['auto','*'], 'layout': 'noBorders'}">
  <caption>key data title</caption>
  <!-- title -->
  <tr>
    <th>
      <img id="pdf-keyData-brandLogo" alt="Brand Logo">
    </th>
    <th>
      <h6 id="pdf-keyData-title"></h6>
    </th>
  </tr>
  <tr>
    <td colspan="2"><h6 id="pdf-keyData-readout-on"></h6></td>
  </tr>
</table>

<!-- vehicle data -->
<table data-pdfmake="{'widths':['auto','*', 30 ,'auto','*'], 'layout': 'noBorders'}">
  <caption>vehicle data</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td><strong id="pdf-keyData-vin-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-vin-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-salesCode-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-salesCode-value"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-deliveryDate-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-deliveryDate-value"></td>
    <td></td>
    <td><strong id="pdf-keyData-productionDate-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-productionDate-value"></td>
  <tr>
    <td><strong id="pdf-keyData-serviceSchedulingActivated-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-serviceSchedulingActivated-value"></td>
    <td></td>
    <td><strong  class="fieldname"></strong></td>
    <td class="align-right" ></td>
  <tr>
    <td colspan="5"></td>
  </tr>
</table>

<!-- vehicle color -->
<table id="pdf-keyData-color" data-pdfmake="{'widths':['auto','*','auto','*','auto'], 'layout': 'noBorders'}">
  <caption>vehicle color</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td></td>
    <td></td>
    <td class="align-right"><strong id="pdf-keyData-colorInterior-fieldname" class="fieldname"></strong></td>
    <td></td>
    <td class="align-right"><strong id="pdf-keyData-colorExterior-fieldname" class="fieldname"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-colorInterior-colorCode-fieldname" class="fieldname"></strong></td>
    <td></td>
    <td id="pdf-keyData-colorInterior-colorCode-value" class="align-right"></td>
    <td></td>
    <td id="pdf-keyData-colorExterior-colorCode-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-colorInterior-description-fieldname" class="fieldname"></strong></td>
    <td></td>
    <td id="pdf-keyData-colorInterior-description-value" class="align-right"></td>
    <td></td>
    <td id="pdf-keyData-colorExterior-description-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-colorInterior-paintNumber-fieldname" class="fieldname"></strong></td>
    <td></td>
    <td id="pdf-keyData-colorInterior-paintNumber-value" class="align-right"></td>
    <td></td>
    <td id="pdf-keyData-colorExterior-paintNumber-value" class="align-right"></td>
  </tr>
  <tr>
    <td colspan="5"></td>
  </tr>
</table>

<table data-pdfmake="{'widths':['auto'], 'layout': 'noBorders'}">
  <caption>sevice key data title</caption>
  <tr>
    <th id="pdf-service-key-data-title"></th>
  </tr>
  <tr>
    <td></td>
  </tr>
</table>

<!-- mileage -->
<table id="pdf-keyData-mileage" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>mileage</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <!-- Mileage Field -->
    <td><strong id="pdf-keyData-mileage-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-mileage-value" class="align-right"></td>
    <td></td>
    <!-- Mileage Timestamp Field -->
    <td><strong id="pdf-keyData-mileageTimestamp-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-mileageTimestamp-value" class="align-right"></td>
  </tr>
  <tr>
    <td ><strong id="pdf-keyData-mileageInfo-fieldname" class="fieldname"></strong></td>
    <td  id="pdf-keyData-mileageInfo-value" class="align-right"></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td colspan="5"> </td>
  </tr>
  <tr>
    <td colspan="5"></td>
  </tr>
</table>

<!-- distances -->
<table id="pdf-keyData-serviceIntervalDisplay" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>distances</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-service-interval-display-title"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-inspectionDistance-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-inspectionDistance-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-inspectionTime-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-inspectionTime-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-oilDistance-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-oilDistance-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-oilTime-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-oilTime-value" class="align-right"></td>
  </tr>
  <tr>
    <td colspan="5"> </td>
  </tr>
</table>

<!-- fluid levels -->
<table id="pdf-keyData-fluidLevels" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>fluid levels</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-fluid-levels-title"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-oilAmount-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-oilAmount-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-oilLevel-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-oilLevel-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-oilDifference-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-oilDifference-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-fuelLevel-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-fuelLevel-value" class="align-right"></td>
  </tr>
  <tr>
    <td colspan="5"> </td>
  </tr>
</table>

<!-- remaining range -->
<table id="pdf-keyData-remainingRangesOfDrives" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>remaining range of drive</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-remaining-ranges-of-drive-title"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-primaryRemainingRange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-primaryRemainingRange-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-secondaryRemainingRange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-secondaryRemainingRange-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-remainingRange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-remainingRange-value" class="align-right"></td>
    <td colspan="3"></td>
  </tr>
  <tr>
    <td colspan="5"> </td>
  </tr>
</table>

<table id="pdf-keyData-adBlue" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>adBlue</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td><strong id="pdf-keyData-adBlue-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-adBlue-value" class="align-right"></td>
    <td colspan="3"></td>
  </tr>
  <tr>
    <td colspan="5"></td>
  </tr>
</table>

<!-- oil norm -->
<table id="pdf-keyData-oilNorm" data-pdfmake="{'widths':['auto','*',30,'auto','*'], 'layout': 'noBorders'}">
  <caption>oil norm</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-oil-norm-title"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-fixedOilChange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-fixedOilChange-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-flexibleOilChange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-flexibleOilChange-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-capacityFixedOilChange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-capacityFixedOilChange-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-capacityFlexibleOilChange-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-capacityFlexibleOilChange-value" class="align-right"></td>
  </tr>
  <tr>
    <td colspan="5"> </td>
  </tr>
</table>

<!-- warnings -->
<div id="pdf-keyData-warnings">
  <table data-pdfmake="{'widths':['*',30,'*'], 'layout': 'noBorders'}">
    <caption>warnings</caption>
    <tr><th colspan="3"></th></tr>
    <tr>
      <td>
        <table data-pdfmake="{'widths':['auto'], 'layout': 'noBorders'}">
          <caption>active warnings</caption>
          <tr><th></th></tr>
          <tr>
            <td class="align-right"><strong id="pdf-keyData-warningMessagesActive-fieldname"></strong></td>
          </tr>
          <tr>
            <td id="pdf-keyData-warningMessagesActive"></td>
          </tr>
        </table>
      </td>
      <td></td>
      <td>
        <table data-pdfmake="{'widths':['auto'], 'layout': 'noBorders'}">
          <caption>historical warnings</caption>
          <tr><th></th></tr>
          <tr>
            <td class="align-right"><strong id="pdf-keyData-warningMessagesHistory-fieldname"></strong></td>
          </tr>
          <tr>
            <td id="pdf-keyData-warningMessagesHistory"></td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="3"> </td>
    </tr>
  </table>
</div>

<!-- battery information -->
<table id="pdf-keyData-batteryInformationData"
  data-pdfmake="{'widths':['auto','*',30,'auto','*'], 'layout': 'noBorders'}">
  <caption>battery information</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-battery-information-title"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-batteryLevelHV-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-batteryLevelHV-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-batteryLevel-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-batteryLevel-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-electricVehicleInformation-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-electricVehicleInformation-value"></td>
    <td colspan="3"></td>
  </tr>
  <tr>
    <td colspan="5"> </td>
  </tr>
</table>

<!-- DTCs -->
<table id="pdf-keyData-diagnosticTroubleCodes" data-pdfmake="{'widths':['auto','*'], 'layout': 'noBorders'}">
  <caption>dtc</caption>
  <tr><th colspan="2"></th></tr>
  <tr>
    <td><strong id="pdf-event-memory-entries-title"></strong></td>
    <td></td>
  </tr>
  <tr>
    <td colspan="2" id="pdf-keyData-diagnosticTroubleCodeEntry"></td>
  </tr>
  <tr>
    <td colspan="2"> </td>
  </tr>
</table>

<!-- energy consumers and hints -->
<table id="pdf-keyData-energyConsumerHints" data-pdfmake="{'widths':['*',30,'*'], 'layout': 'noBorders'}">
  <caption>energy consumer and hints</caption>
  <tr><th colspan="3"></th></tr>
  <tr>
    <td>
      <table data-pdfmake="{'widths':['*', 'auto'], 'layout': 'noBorders'}">
        <caption>energy consumer</caption>
        <tr><th colspan="2"></th></tr>
        <tr>
          <td colspan="2" class="align-right"><strong id="pdf-keyData-energyConsumers-fieldname" class="fieldname"></strong></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-firstConsumer-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-firstConsumer-value"></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-secondConsumer-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-secondConsumer-value"></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-thirdConsumer-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-thirdConsumer-value"></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-energyPotential-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-energyPotential-value"></td>
        </tr>
      </table>
    </td>
    <td></td>
    <td>
      <table data-pdfmake="{'widths':['*', 'auto'], 'layout': 'noBorders'}">
        <caption>energy hints</caption>
        <tr><th colspan="2"></th></tr>
        <tr>
          <td colspan="2" class="align-right"><strong id="pdf-keyData-energyHints-fieldname" class="fieldname"></strong></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-firstEnergyHint-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-firstEnergyHint-value"></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-secondEnergyHint-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-secondEnergyHint-value"></td>
        </tr>
        <tr>
          <td><strong id="pdf-keyData-thirdEnergyHint-fieldname" class="fieldname"></strong></td>
          <td class="align-right" id="pdf-keyData-thirdEnergyHint-value"></td>
        </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td colspan="3"> </td>
  </tr>
</table>

<!-- emission data - wltp -->
<table id="pdf-keyData-wltpData" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>wltp</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-keyData-wltpData-fieldname" class="fieldname"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-wltpData-low-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-wltpData-low-value"></td>
    <td></td>
    <td><strong id="pdf-keyData-wltpData-extraHigh-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-wltpData-extraHigh-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-wltpData-medium-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-wltpData-medium-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-wltpData-combined-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-wltpData-combined-value" class="align-right"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-wltpData-high-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-wltpData-high-value" class="align-right"></td>
    <td></td>
    <td><strong id="pdf-keyData-wltpData-emissionCombined-fieldname" class="fieldname"></strong></td>
    <td id="pdf-keyData-wltpData-emissionCombined-value" class="align-right"></td>
  </tr>
  <tr>
    <td colspan="5"></td>
  </tr>
</table>

<!-- emission data - nedc -->
<table id="pdf-keyData-nedcData" data-pdfmake="{'widths':['*','*',30,'*','*'], 'layout': 'noBorders'}">
  <caption>nedc</caption>
  <tr><th colspan="5"></th></tr>
  <tr>
    <td colspan="5"><strong id="pdf-keyData-nedcData-fieldname" class="fieldname"></strong></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-nedcData-innerCity-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-nedcData-innerCity-value"></td>
    <td></td>
    <td><strong id="pdf-keyData-nedcData-emissionCombined-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-nedcData-emissionCombined-value"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-nedcData-outOfTown-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-nedcData-outOfTown-value"></td>
    <td></td>
    <td><strong id="pdf-keyData-nedcData-energyEfficiency-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-nedcData-energyEfficiency-value"></td>
  </tr>
  <tr>
    <td><strong id="pdf-keyData-nedcData-combined-fieldname" class="fieldname"></strong></td>
    <td class="align-right" id="pdf-keyData-nedcData-combined-value"></td>
    <td></td>
    <td><strong></strong></td>
    <td class="align-right"></td>
  </tr>
</table>
