/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input } from '@angular/core';
import { GdprService } from '@services/gdpr/gdpr.service';
import { DisplayModeEnum } from '@utils/enums/display-mode.enum';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component displays the footer and the modal window
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  // ids of the footer items, used to call the right element from translation json
  @Input() footerItems!: Array<string>;

  // current selected item, used to call the right text from translation json for the modal window
  public selectedItem: { topic: string, displayMode: DisplayModeEnum } = { topic: '', displayMode: DisplayModeEnum.text };

  // contains the content of the modal window that is to be displayed
  public modalContent: string | any = '';

  // is true if text in the form of html is to be displayed in the modal window and false if the pdf is to be displayed in the pdf viewer.
  public displayText: boolean = true;

  modalWindowCloseButton: HTMLElement;
  firstTabOnModalWindow = false;
  /**
   * Creates an instance of the ShellComponent
   * @param gdprService handles the gdpr information
   * @param translate transation handling
   */
  constructor(private gdprService: GdprService, private translate: TranslateService) { }

  /**
   * Open or close the modal window and request the content for the selected topic
   *
   * @param open the modal window should be opened (true) or closed (false)
   * @param footerItem selected footer item
   */
  toggleModalWindow(open: boolean, footerItem:string): void {
    this.selectedItem = { topic: footerItem, displayMode: DisplayModeEnum.text };
    this.modalContent = '';
    this.displayText = true;
    const modalWindow: any = document.getElementById('footerModal');
    modalWindow['displayed'] = open;
    this.firstTabOnModalWindow = open;
    if (modalWindow && (modalWindow['displayed'] !== null)) {
      // endpoint returns an 'arrayBuffer' as responseType, because both text and a PDF can be generated from it.
      this.gdprService.getGdprData(footerItem).subscribe((res: HttpResponse<ArrayBuffer>) => {
        // check if the content type is a text
        this.displayText = res.headers.get('Content-Type') === 'text/html';
        if (res.body && this.displayText) {
          this.modalContent = String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(res.body)));
          const modalWindowContent = document.getElementById('displayText');
          if(modalWindowContent) {
            modalWindowContent.innerHTML = this.modalContent;
            this.addEventListenerToCloseButtonAndHighlightModal(footerItem);
          }
        } else if (res.body) {
          this.modalContent = new Uint8Array(res.body);
          this.addEventListenerToCloseButtonAndHighlightModal(footerItem);
        }

      },
      // In the case of an error, an Unhappy path is displayed.
        (error: HttpErrorResponse) => {
          this.modalContent = this.translate.instant('shell.footer.no-content');
          const modalWindowContent = document.getElementById('displayText');
          if(modalWindowContent) {
            modalWindowContent.innerHTML = this.modalContent;
          }
          this.displayText = true;
          this.addEventListenerToCloseButtonAndHighlightModal(footerItem);
        });
      // modalWindow['displayed'] = open;
    }
  }

  /**
   * Adds an event listener to a close button element and highlights the modal window after a delay.
   *
   * @param {string} footerItem - The footer item associated with the modal window.
   */
  addEventListenerToCloseButtonAndHighlightModal(footerItem: string){
    setTimeout(() => {
      this.makeCloseButtonHighlightableAndAddEventListener(footerItem);
    }, 500);
  }

  /**
   * Makes the close button highlightable and adds a keydown event listener to it.
   *
   * @param {string} footerItem - The footer item associated with the modal window.
   */
  makeCloseButtonHighlightableAndAddEventListener(footerItem: string) {
    this.modalWindowCloseButton = document
      .querySelector('groupui-modal')
      ?.shadowRoot?.getElementById('close-button')!;
    if (this.modalWindowCloseButton) {
      this.modalWindowCloseButton.setAttribute('autofocus', '');
      this.modalWindowCloseButton.setAttribute('tabindex', '0');
      this.addEventListenerToModalWindowCloseButton(footerItem);
    }
    this.addEventListenerToFocusGuardFooter();
  }

  /**
   * Adds a keydown event listener to the modal window's close button for keyboard interaction.
   *
   * @param {string} footerItem - The footer item associated with the modal window.
   */
  addEventListenerToModalWindowCloseButton(footerItem: string) {
    this.modalWindowCloseButton.addEventListener('keydown', (event: KeyboardEvent) => {
      switch (event.code) {
        // Selecting a language
        case 'Space':
        case 'Enter':
          this.toggleModalWindow(false, footerItem);
          break;
      }
    });
  }

  /**
   * Adds a focus event listener to a focus guard footer element.
   */
  addEventListenerToFocusGuardFooter(){
    document.querySelector('#focusguardfooter')?.addEventListener('focus', () => {
      this.modalWindowCloseButton.focus();
    });
  }

  /**
   * Handles keyboard event for iterating through elements within the modal window.
   *
   * @param {KeyboardEvent} event - The keyboard event object.
   */
  iterateThrewElements(event: KeyboardEvent) {
    if (event.code === 'Tab' && this.firstTabOnModalWindow) {
      this.modalWindowCloseButton.focus();
      this.firstTabOnModalWindow = false;
    }
  }
}
